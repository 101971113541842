import React from "react";

const Contact = () => {
    return (
        <div>
            <section id="get-in-touch" data-aos="fade-up">
                <span>Lorem ipsum dolor sit amet, consectetur adipisicing.</span>
                <a href="#">Lorem ipsum</a>
            </section>
            <section id="contact">
                <form data-aos="fade-up">
                    <input
                        type="text"
                        name="fullname"
                        placeholder="Full Name"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Example@gmail.com"
                        required
                    />
                    <textarea
                        name="message"
                        placeholder="Write Something...."
                        required
                    />
                    <input type="submit" value="Send"/>
                </form>
            </section>
            <footer>
                <div className="footer-social">
                    <a href="#">
                        <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                        <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                        <i className="fab fa-youtube"></i>
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Contact;
