import React from "react";
import LogoIMG from "../images/logo.png";

const Header = () => {
    return (
        <>
            <nav className="navigation">
                <a href="#" className="logo">
                    <img src={LogoIMG} alt="logo" />
                </a>
                <input type="checkbox" className="menu-btn" id="menu-btn"/>
                <label htmlFor="menu-btn" className="menu-icon">
                    <span className="nav-icon"></span>
                </label>
                <ul className="menu">
                    <li><a href="#main">Home</a></li>
                    <li><a href="#skills">Skills</a></li>
                    <li><a href="#introduction">Introduction</a></li>
                    <li><a href="#services">Services</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
                <a href="#" className="hire-me">Hire Me <i className="fas fa-chevron-right"></i></a>
            </nav>
        </>
    );
};

export default Header;
