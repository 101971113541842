import React from "react";
import SkillImg2 from "../images/adomas-aleno-4vrZpOo7fTc-unsplash.jpg";
import webpack from "../images/webpack.png";
import react from "../images/react.png";

const Skills = () => {
    return (
        <div>
            <section id="skills">
                <div className="skills-text-section">
                    <div className="skill-heading" data-aos="fade">
                        <span>My Skills</span>
                        <h2>My Experties</h2>
                    </div>
                    <div className="s-box-container">
                        <div className="skill-box" data-aos="fade-up">
                            <div className="s-box-icon">
                                <i className="fab fa-html5"></i>
                            </div>
                            <div className="s-box-text">
                                <strong>
                                    Hyper Text Markup Language(HTML)
                                </strong>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda mollitia quisquam quod repudiandae tenetur!
                                </p>
                                <div className="progress-line html">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="skill-box" data-aos="fade-up">
                            <div className="s-box-icon">
                                <i className="fab fa-css3-alt"></i>
                            </div>
                            <div className="s-box-text">
                                <strong>Cascading Style Sheets(CSS)</strong>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda mollitia quisquam quod repudiandae tenetur!
                                </p>
                                <div className="progress-line css">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="skill-box" data-aos="fade-up">
                            <div className="s-box-icon">
                                <i className="fab fa-js"></i>
                            </div>
                            <div className="s-box-text">
                                <strong>JavaScript(JS)</strong>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda mollitia quisquam quod repudiandae tenetur!
                                </p>
                                <div className="progress-line js">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="skill-img">
                    <img data-aos="fade" src={SkillImg2} />
                </div>
            </section>
            <section id="skills">
                <div className="skill-img">
                    <img data-aos="fade" src={SkillImg2} />
                </div>
                <div className="skills-text-section">
                    <div className="skill-heading" data-aos="fade">
                        <span>My Skills</span>
                        <h2>My Experties</h2>
                    </div>
                    <div className="s-box-container">
                        <div className="skill-box" data-aos="fade-up">
                            <div className="s-box-icon">
                                <i className="fab fa-git"/>
                            </div>
                            <div className="s-box-text">
                                <strong>
                                    Version controls(Git)
                                </strong>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda mollitia quisquam quod repudiandae tenetur!
                                </p>
                                <div className="progress-line git">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="skill-box" data-aos="fade-up">
                            <div className="s-box-icon">
                                <img className="webpack" src={webpack} alt=""/>
                            </div>
                            <div className="s-box-text">
                                <strong>Webpack is a module bundler.(Webpack)</strong>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda mollitia quisquam quod repudiandae tenetur!
                                </p>
                                <div className="progress-line webpack_class">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className="skill-box" data-aos="fade-up">
                            <div className="s-box-icon">
                                <img className="webpack" src={react} alt=""/>
                            </div>
                            <div className="s-box-text">
                                <strong>React makes it painless to create interactive UIs.(React)</strong>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda mollitia quisquam quod repudiandae tenetur!
                                </p>
                                <div className="progress-line js">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default Skills;
