import React from "react";
import ModelIMG from "../images/model-3.png";
import ModelBgIMG from "../images/model-bg.svg";

const Main = () => {
    return (
        <>
            <section id="main">
                <div className="main-text">
                    <h1>Hey, I'm a Front-End Developer</h1>
                    <p>
                        My fullname is Ilkhom Abdugafurov and i Front-End Developer
                    </p>
                    <div className="main-btns">
                        <a href="#" className="download-cv-btn">
                            Download CV
                        </a>
                        <a href="#" className="hire-me-btn">
                            Hire Me
                        </a>
                    </div>
                </div>
                <div className="model-bg">
                    <img data-aos="fade" data-aos-duration="3000" src={ModelBgIMG} alt="model-bg"/>
                </div>
                <div className="model">
                    <img src={ModelIMG} alt="model"/>
                </div>
            </section>
        </>
    );
};

export default Main;
