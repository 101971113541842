import React from "react";

const Services = () => {
    return (
        <div>
            <section id="services">
                <strong className="services-heading" data-aos="fade-up">
                    Services
                </strong>
                <div className="services-box-container">
                    <div className="service-box" data-aos="fade-up">
                        <i className="fas fa-code"></i>
                        <strong>Front-End</strong>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, esse, rerum? Autem,
                            expedita id nulla odit quaerat quidem saepe sunt tempore? Animi aspernatur incidunt ipsum
                            iure magni molestiae nisi officia porro soluta tenetur velit, veniam voluptas voluptates.
                            Dolores, eligendi, quis.
                        </p>
                    </div>
                    <div className="service-box" data-aos="fade-up">
                        <i className="fas fa-cogs"></i>
                        <strong>Back-End</strong>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, esse, rerum? Autem,
                            expedita id nulla odit quaerat quidem saepe sunt tempore? Animi aspernatur incidunt ipsum
                            iure magni molestiae nisi officia porro soluta tenetur velit, veniam voluptas voluptates.
                            Dolores, eligendi, quis.
                        </p>
                    </div>
                    <div className="service-box" data-aos="fade-up">
                        <i className="fas fa-drafting-compass"></i>
                        <strong>Graphic Designing</strong>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, esse, rerum? Autem,
                            expedita id nulla odit quaerat quidem saepe sunt tempore? Animi aspernatur incidunt ipsum
                            iure magni molestiae nisi officia porro soluta tenetur velit, veniam voluptas voluptates.
                            Dolores, eligendi, quis.
                        </p>
                    </div>
                    <div className="service-box" data-aos="fade-up">
                        <i className="far fa-chart-bar"></i>
                        <strong>SEO</strong>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, esse, rerum? Autem,
                            expedita id nulla odit quaerat quidem saepe sunt tempore? Animi aspernatur incidunt ipsum
                            iure magni molestiae nisi officia porro soluta tenetur velit, veniam voluptas voluptates.
                            Dolores, eligendi, quis.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Services;
